import React, { useState, useEffect } from "react";
import Link from "next/link";

import Logo from "./Logo";
import { NavLinkMobile } from "./NavLinkMobile";
import Hamburger from "./Hamburger";

function NavBarMobile({ navigation }) {
  const [show, setShow] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    // document.body.classList.add("mobileMenuShow");

    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(false);
        } else {
          setShow(true);
        }
        setLastScrollY(window.scrollY);

        if (window.scrollY > 0) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("load", controlNavbar);
      window.addEventListener("scroll", controlNavbar);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div>
      <nav
        className={`mainNavMobile ${!show ? "navHidden" : ""} ${!isTop ? "floating" : ""}`}
      >
        <div className="flex h-full w-full justify-between items-center">
          <Link href="/">
            <a className="Logo">
              <Logo />
            </a>
          </Link>
        </div>
      </nav>
      <Hamburger />
      <div className="mobileCanvasOverlay"></div>
      <div className="mobileOffCanvas">
        <div className="mobileMenu">
          <div className="mobileMenuContainer">
            <div>
              {typeof navigation !== "undefined" && navigation.length > 0 ? (
                <ul className={`navBar`}>
                  {navigation.map(function (link, i) {
                    return (
                      <li key={i}>
                        {/* <NavLink link={link} exact mobile={true} /> */}
                        <NavLinkMobile link={link} exact mobile={true} />
                      </li>
                    );
                  })}
                  <li>
                    <div className="flex items-start">
                      <div className="dropdown-toggle"></div>
                      <Link href="/booking">
                        <a onClick={() => {
                          document.body.classList.remove("mobileMenuOpened");
                          document
                            .querySelector("button.burger")
                            .classList.remove("menu_open");
                          document
                            .querySelector("body, html")
                            .classList.remove("no-scroll");
                        }}
                        className="contact-btn btn-shine">
                          Book now
                        </a>
                      </Link>
                    </div>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBarMobile;

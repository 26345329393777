import React, { useState, useEffect, useRef } from 'react'
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { sendMail } from "../lib/api-lib";
import Link from 'next/link';

function CmsFormNewsletter({ component, page_id='' }) {
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showResult, setShowResult] = useState(0);
  const [bottomReached, setbottomReached] = useState(false);
  const [privacyWarning, setPrivacyWarning] = useState(false);

  const form_data = {
    name: '',
    surname: '',
    email: '',
  };

  const handleSubmit = async (event) => {
    // console.log(event);
    event.preventDefault();

      const data = {
        name: event.target.name.value,
        surname: event.target.surname.value,
        email: event.target.email.value,
      };

      setShowLoading(true);

      // const response = await sendMail(data);

      fetch(process.env.BASE_URL + "/api/subscribe", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setShowLoading(false);
        if (data.response == 1) {
          setShowResult(1);
        } else {
          setShowResult(2);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        setShowResult(2);
      });
  };

  const listInnerRef = useRef();

  return (
    <>
      {showModal ? (
        <>
          <div className="contact-form">
            <div className="contact-form-container relative">
              {showResult == 0 ? (
                <>
                  {showLoading ? (
                    <div className="absolute w-full h-full theme-b5 z-10 flex flex-col justify-center items-center">
                      <TailSpin
                        type="Puff"
                        color="#15A1b1"
                        height={70}
                        width={70}
                        timeout={3000}
                      />
                      <div>sending...</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*content*/}
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="form-contact">
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        
                        <div className="form-row grid md:grid-cols-2 gap-4">
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_email"
                            >
                              Email <span className="required">*</span>
                            </label>
                            <input
                              id="form_email"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Email (obbligatorio)"
                              name="email"
                              defaultValue={form_data.email}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-row  grid md:grid-cols-2 gap-4">
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              First name
                            </label>
                            <input
                              id="form_name"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="First name"
                              name="name"
                              defaultValue={form_data.name}
                            />
                          </div>
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_surname"
                            >
                              Surname
                            </label>
                            <input
                              id="form_surname"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Surname"
                              name="surname"
                              defaultValue={form_data.surname}
                            />
                          </div>
                        </div>
                        
                      </div>
                      {/*footer*/}

                      <div className="flex items-center justify-center pb-6 border-t border-solid border-slate-200 rounded-b relative">
                        <input type="checkbox" value="1" id="form_terms" name="terms" className="mr-4 cursor-pointer"
                        required
                        />
                          
                          <label
                            className={`font-bold cursor-pointer`}
                            htmlFor="form_terms"
                          >
                            Accept the <Link href="/privacy-policy"><a target="_blank">Privacy Policy</a></Link> and consent to the processing of my personal data.
                          </label>
                      </div>
                      <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="externalBtn" type="submit">
                          SUBSCRIBE
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="form-cms-container-inner">
                    <div className="relative p-6 flex-auto">
                      <div className="z-10 flex flex-col justify-center items-center">
                        {showResult == 1 ? (
                          <>
                            <h4 className="theme-c2 text-center">
                              Subscription successfully done!
                            </h4>
                            <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                            <button className="externalBtn" type="button" onClick={() => { setShowResult(0) }}>
                              CLOSE
                            </button>
                          </div>
                          </>
                        ) : (
                          <>
                            <h5 className="text-red-600 text-center">
                              An error has occurred!
                            </h5>
                            <p className="font-normal mt-8 text-center">
                              We apologize for any inconvenience.
                              <br />
                              Please contact us by writing to{" "}
                              <a
                                href="mailto:tarponville@yahoo.com"
                                className="theme-c2"
                              >
                                tarponville@yahoo.com
                              </a>
                            </p>
                            <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                              <button className="externalBtn" type="button" onClick={() => { setShowResult(0) }}>
                                RETRY
                              </button>
                            </div>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default CmsFormNewsletter;

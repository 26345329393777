import dynamic from "next/dynamic";

import CmsSocial from "./CmsSocial";

import CmsForm from "./CmsForm";
import CmsFormNewsletter from "./CmsFormNewsletter";
import CmsBookForm from "./CmsBookForm";
// import CmsSwiperIntro from "./CmsSwiperIntro";

const dynamicComponents = {
  // slideshow: dynamic(() => import("./CmsSlideshow")),
  text: dynamic(() => import("./CmsText")),
  image: dynamic(() => import("./CmsImage")),
  card: dynamic(() => import("./CmsCard")),
  cardGroup: dynamic(() => import("./CmsCardGroup")),
  swiper: dynamic(() => import("./CmsSwiper")),
  swiperIntro: dynamic(() => import("./CmsSwiperIntro")),
  video: dynamic(() => import("./CmsVideo")),
  attachment: dynamic(() => import("./CmsAttachment")),
  link: dynamic(() => import("./CmsButton")),
  map: dynamic(() => import("./CmsMap"), { ssr: false })
};

function CmsRow({ row, page_id = "" }) {

  var rowID = row.id ?? "";

  return (
    <section id={rowID}>
      <div className={row.full ? "container-xxl mx-auto" : `container mx-auto`}>
        <div
          className={
            row.columns != "full" && row.columns > 1
              ? `griglia grid-cols-${row.columns} gap-8`
              : ""
          }
        >
          {row.components.map(function (component, i) {
            component.name = component.name == "card-group" ? "cardGroup" : component.name;
            component.name = component.id == "introslide" ? "swiperIntro" : component.name;
            component.name = component.id == "cms-map" ? "map" : component.name;

            const Component = dynamicComponents[component.name];

            component.options.columns = typeof component.options.columns != "undefined" ? component.options.columns : 1;

            if (typeof Component != "undefined") {
              
              if(component.id == "contact-form") {

                return (
                  <CmsForm component={component} page_id={page_id} key={i} />
                );

              } else if(component.id == "newsletter-form") {

                return (
                  <CmsFormNewsletter component={component} page_id={page_id} key={i} />
                );

              } else if(component.id == "book-form") {

                return (
                  <CmsBookForm component={component} page_id={page_id} key={i} />
                );

              } else {

                return (
                <div
                    className={`col-span-${component.options.columns}`}
                    key={i}
                  >
                    <Component component={component} page_id={page_id} />
                  </div>
                )

              }

            } else {
              return (
                <div key={i}>
                  {component.id == "footer-social" ? (
                    <div>
                      <h5>Social</h5>
                      <CmsSocial />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
}

export default CmsRow;

import React, { useState, useEffect } from "react";
import Link from "next/link";

import Logo from "./Logo";
import { NavLink } from "./NavLink";

import { CalendarIcon } from "@heroicons/react/solid";

const Navbar = ({ navigation }) => {
  const [show, setShow] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(false);
        } else {
          setShow(true);
        }
        setLastScrollY(window.scrollY);

        if (window.scrollY > 0) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("load", controlNavbar);
      window.addEventListener("scroll", controlNavbar);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <nav
      className={`mainNav ${!show ? "navHidden" : ""} ${
        !isTop ? "floating" : ""
      } `}
    >
      <div className="container mx-auto h-full">
        <div className="flex h-full w-full justify-between items-center">
          <Link href="/">
            <a className="Logo">
              <Logo />
            </a>
          </Link>
          {typeof navigation !== 'undefined' && navigation.length > 0 ? (
            <ul className={`navBar`}>
              {navigation.map(function (link, i) {
                return (
                  <li key={i}>
                    <NavLink link={link} exact="true"></NavLink>
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
          <div className="h-full relative">
            <Link href="/booking">
              <a className="contact-btn btn-shine">
                <CalendarIcon className="calendar-icon" />
                Book now
              </a>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

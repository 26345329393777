import React, { useState, useEffect } from "react";

import styles from './Hamburger.module.scss'

function Hamburger({}) {
    const [show, setShow] = useState(true);
    const [isTop, setIsTop] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // const [isActive, setIsActive] = useState(0);

    function toogleBurger(e) {
        // setIsActive(!isActive)
        if(!document.querySelector('button.burger').classList.contains('menu_open')) {
            document.body.classList.add('mobileMenuOpened');
            document.querySelector("body, html").classList.add("no-scroll");
            document.querySelector('button.burger').classList.add("menu_open")
        } else {
            document.body.classList.remove('mobileMenuOpened');
            document.querySelector("body, html").classList.remove("no-scroll");
            document.querySelector('button.burger').classList.remove("menu_open")
        }
    }

    useEffect(() => {
        // document.body.classList.add("mobileMenuShow");
    
        const controlNavbar = () => {
          if (typeof window !== "undefined") {
            if (window.scrollY > lastScrollY) {
              setShow(false);
            } else {
              setShow(true);
            }
            setLastScrollY(window.scrollY);
    
            if (window.scrollY > 0) {
              setIsTop(false);
            } else {
              setIsTop(true);
            }
          }
        };
    
        if (typeof window !== "undefined") {
          window.addEventListener("load", controlNavbar);
          window.addEventListener("scroll", controlNavbar);
          // cleanup function
          return () => {
            window.removeEventListener("scroll", controlNavbar);
          };
        }
      }, [lastScrollY]);

  return (
    <button onClick={toogleBurger} className={`btn_menu burger ${!show ? "navHidden" : ""}`} type="button">
        <i className={`btn_menu__bars`} aria-hidden="true"></i>
    </button>
  );
}

export default Hamburger;
